function occhiali() {
    return {
        items: [],
        url: stringa,
        ListaOcchiali: [],
        limite: 12,
        loading: true,
        linkVendita: shopUrl + "/index.php?controller=product&codice_articolo=",
        linkProva: "https://glasson.sk-global.biz/try-on/thema/",
        richiesta: async function () {
            const response = await fetch(
                `${this.url}`
            );
            const data = await response.json();

            console.log(data)

            this.loading = false;
            this.items = data;

            this.items = this.items.filter(function (occhiale) {

                // questo è un "embrione" di un controllo che aggiunge un'immagine di default se si decidesse di usare una immagine standard per tutti gli occhiali che mancano
                // if (occhiale.image.length == 0) {
                //     occhiale.image.push("{f:uri.resource(package: 'Proteinic.Greenvision', path: 'Images/occhiali.png', localize:false)}")
                // }

                // Questa è la condizione che toglie gli elementi senza immagini che fa bloccare tutto quanto

                // if(occhiale.variants){
                //     var varianti = occhiale.variants;
                //     var occhiale_senza_varianti = occhiale;
                //     occhiale_senza_varianti.variants = null;

                //     varianti.unshift(occhiale_senza_varianti);
                //     occhiale.variants = varianti;
                // }
                if (occhiale.images.length == 0) {
                    // console.log({ occhiale });
                    // return false;
                }
                else {
                    if (occhiale.variants) {

                        occhiale.variants.forEach(function (element, index = 0, object) {
                            if (element.images.length == 0) {
                                // console.log({ index });
                                object.splice(index, 1);
                            }
                        });
                    }
                    
                    if (occhiale.variants) {
                        var varianti = occhiale.variants;
                        var obj = {
                            code: occhiale.code,
                            color: occhiale.color,
                            model: occhiale.model,
                            images: occhiale.images,
                            urlTryOn: occhiale.urlTryOn
                        };
                        varianti.unshift(obj);
                    }

                    // Per limite di alpine dobbiamo togliere gli elementi che non hanno delle immagini
                    if (occhiale.images.length > 0) {
                        return occhiale;
                    }
                }
            });

            // Aggiunge i filtri in base a cosa trova negli occhiali
            this.items.forEach(element => {
                // Il toLowerCase è necessario, perchè se i valori hanno un carattere diverso poi ci saranno due filtri, che a front-end sono uguali
                element.gender = element.gender.toLowerCase();
                element.shape = element.shape.toLowerCase();
                element.size = element.size.toLowerCase();
                element.material = element.material.toLowerCase();
                element.type = element.type.toLowerCase();
                element.brand = element.brand.toLowerCase();
                
                if (!this.filtroGender.includes(element.gender)) {
                    this.filtroGender.push(element.gender);
                }
                if (!this.filtroShape.includes(element.shape)) {
                    this.filtroShape.push(element.shape);
                }
                if (!this.filtroSize.includes(element.size)) {
                    this.filtroSize.push(element.size);
                }
                if (!this.filtroMaterial.includes(element.material)) {
                    this.filtroMaterial.push(element.material);
                }
                if (!this.filtroType.includes(element.type)) {
                    this.filtroType.push(element.type);
                }
                if (!this.filtroBrand.includes(element.brand)) {
                    this.filtroBrand.push(element.brand);
                }
            });


            this.ListaOcchiali = this.items;
            this.items = this.items.slice(0, this.limite)
        },

        mostraGender: false,
        mostraType: false,
        mostraBrand: false,
        mostraMaterial: false,
        mostraShape: false,
        mostraSize: false,
        filtroGender: [],
        filtroShape: [],
        filtroMaterial: [],
        filtroSize: [],
        filtroType: [],
        filtroBrand: [],
        filtriInseriti: [],
        mostraModale: false,
        mostraFrame: false,
        mostraImmagine: true,
        mostraFiltri: false,
        modale: [],
        immaginiSlide: [],
        varianti: [],
        listaFiltrata: [],
        lunghezzaMassima: 0,
        slideAttiva: 0,
        urlFrame: "",
        urlVendita: "",
        urlProva: "",
        toggleFiltro(filtro, caratteristica) {
            this.limite = 12;
            if (this.filtriInseriti[caratteristica] !== undefined
                && this.filtriInseriti[caratteristica].includes(filtro)) {
                // filtro già impostato, togliamo
                delete this.filtriInseriti[caratteristica];
            } else {
                this.filtriInseriti[caratteristica] = filtro;
            }

            this.aggiornaItems();
        },
        aggiornaItems() {
            var tmp = this.ListaOcchiali; // partiamo sempre dall'elenco completo
            var filtriInseriti = this.filtriInseriti; // non riesco ad usare la variabile di alpine

            for (var caratteristica in filtriInseriti) { // ciclo l'array per chiave
                tmp = tmp.filter(function (item) {
                    if (item[caratteristica] == filtriInseriti[caratteristica]) {
                        return item;
                    }
                });
            }

            this.listaFiltrata = tmp;
            this.items = tmp;
            this.items = this.items.slice(0, this.limite);
        },
        popolaModale(item) {
            item.size = item.size.charAt(0).toUpperCase() + item.size.slice(1);
            item.material = item.material.charAt(0).toUpperCase() + item.material.slice(1);
            item.gender = item.gender.charAt(0).toUpperCase() + item.gender.slice(1);
            this.modale = item;
            this.costruisciFrame(item);
            this.costruisciVendita(item);
            this.slideAttiva = 0;
            this.immaginiSlide = item.images;
            this.varianti = item.variants;
        },
        slideSuccessiva() {
            this.slideAttiva = (this.slideAttiva < this.modale.images.length - 1) ? this.slideAttiva + 1 : 0;
        },
        slidePrecedente() {
            this.slideAttiva = (this.slideAttiva > 0) ? this.slideAttiva - 1 : this.modale.images.length - 1;
        },
        aggiungiProdotti: function () {
            var scrollSpy = document.querySelector(".carica-altri");

            if (this.isElementVisible(scrollSpy) && !this.loading) {
                this.limite += 6;
                // if (this.limite >= this.listaFiltrata.length) {
                //     this.limite = this.listaFiltrata.length;
                // }
                this.aggiornaItems();
                this.items = this.items.slice(0, this.limite);
            }
        },
        clickFiltri: function () {
            this.mostraFiltri = !this.mostraFiltri;
        },
        isElementVisible: function (el) {
            var rect = el.getBoundingClientRect(),
                vWidth = window.innerWidth || document.documentElement.clientWidth,
                vHeight = window.innerHeight || document.documentElement.clientHeight,
                efp = function (x, y) { return document.elementFromPoint(x, y) };
            if (rect.right < 0 || rect.bottom < 0
                || rect.left > vWidth || rect.top > vHeight)
                return false;
            return (
                el.contains(efp(rect.left, rect.top))
                || el.contains(efp(rect.right, rect.top))
                || el.contains(efp(rect.right, rect.bottom))
                || el.contains(efp(rect.left, rect.bottom))

            );
        },
        costruisciVendita: function (item) {
            this.urlVendita = shopUrl + "/index.php?controller=product&codice_articolo=" + item.code;
        },
        costruisciFrame: function (item) {
            this.urlProva = "https://glasson.sk-global.biz/try-on/thema/" + item.code + "/" + item.model;
        },
        cambiaProdotto: function (oggetto) {
            if (oggetto.images.length != 0) {
                this.modale.code = oggetto.code;
                this.immaginiSlide = oggetto.images;
                this.modale.model = oggetto.model;
                this.modale.color = oggetto.color;
                this.modale.urlTryOn = oggetto.urlTryOn;
            }
            // console.log(oggetto)
            // this.costruisciVendita(oggetto);
        },
        checkMiniatura: function (oggetto) {
            try {
                if (typeof oggetto.images[0] !== 'undefined') {
                    return oggetto.images[0].thumb
                }
                else
                    return baseUrl + "_Resources/Static/Packages/Proteinic.Greenvision/Images/default.png";
            }
            catch (e) {
                console.log(e)
            }
        },
        checkEsistenza: function (oggetto) {
            try {
                if(oggetto.images.length == 0)
                    return true;
            }
            catch (e) {
                console.log(e)
            }
        }
    }
}


export { occhiali }